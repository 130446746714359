@import "config";

.menuContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 68px;
  background-color: var(--main-menu-background-color);
  backdrop-filter: saturate(180%) blur(10px);
  position: fixed;
  width: 100vw;
}
