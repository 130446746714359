@import "config";

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.input {
  max-width: 100rem;
}

.table {
  width: 100%;
  height: 97%;
}

.summary {
  padding: 1rem;
  width: 100%;
  height: 97%;
  overflow: hidden;
}

.editLayout {
  background-color: var(--charts-form-layout-color);;
  border: 2px solid var(--border-chart-layout-color);
}

.modal {
  padding: 2rem;
  min-width: 1000px;

  .row {
    display: flex;
    justify-content: space-between;

    .chartSelect {
      margin-top: 10rem;
    }
    .select {
      width: 45rem;
    }
  }

  .chartView {
    max-width: 100rem;
    height: 30rem;
    overflow: auto;
  }

  .actions {
    display: flex;
    margin-top: 2rem;
    justify-content: flex-end;

    button + button,
    button + div,
    div + button {
      margin-left: 1.2rem;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1rem;
}

.btnEditMode {
  margin: 1rem;
}

.btnsFilter {
  margin: 1rem;
  display: flex;
  align-items: center;
}

.filtersmodal {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 2rem;
}

.margintop {
  margin-top: 3rem
}

.layout {
  background-color: blue;
}
