.container {
  background-color: var(--user-notifications-background-color);

  .itemsContainer {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: calc(100vh - 68px - 4.8rem);
    background-color: var(--user-notifications-items-conatiner-background-color);
    min-width: 500px;

    .inner {
      width: 100%;
      min-height: 0;
      display: flex;
      flex-direction: column;

      &::-webkit-scrollbar {
        width: 0;
        background: transparent;
      }

      .searchContainer {
        border-radius: 1.2rem;
        padding: 0.5rem;
        margin: 1.5rem 4.5rem 3rem 3rem;
        background-color: var(--primary-body-color);

        .searchBar {
          flex: 1;
          background: transparent;
          border-bottom: none;
        }
      }

      .settingsClass {
        margin: 2.5rem 3.5rem 2.3rem 3.5rem;
        text-decoration: none;
        display: flex;
        align-items: center;
        color: grey;

        .notificationSettings {
          //color: var(--primary-text-color);
          //cursor: pointer;
          color: grey;
          font-weight: 600;
          letter-spacing: 0;
          font-size: 14px;
          margin-left: 5px;
        }
      }
    }

    .extra {
      border-top: solid .1rem var(--vidazoo-apps-extra-apps-border-color);
    }

    .notifications {
      width: 100%;
      max-width: 500px;
      overflow: auto;
    }
  }
}

.notificationMessageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  .topBar {
    display: flex;
    justify-content: space-between;

    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .controls {
        display: flex;
        margin: 2rem;

        [class*='icon'] {
          color: var(--primary-text-color);
        }

        .icon {
          flex-direction: column;
          margin-right: 2px;
          background-color: transparent;

          &.disabled {
            [class*='icon'] {
              color:  #ebebed;
            }
          }
        }
      }

      .count {
        font-size: 14px;
        color: var(--primary-text-color);
      }
    }

  }
}

.notificationEmpty {
  display: flex;
  align-self: center;
  margin: 0 auto;
}

.pageMessageContainer {
  height: calc(100vh - 68px - 4.8rem);
  display: flex;
  justify-content: center;
  background-color: var(--user-notifications-background-color);
  align-items: center;
}
