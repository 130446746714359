.header {
  padding: 1rem 2rem;
  border-bottom-color: var(--color-gray-3);
  border-bottom-width: .1rem;

  .top {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    .date {
      width: 13.0rem !important;
      min-width: auto;
      margin-right: 1.2rem;
    }

    .hour {
      width: 10.0rem !important;
    }
  }

  .actions {
    padding-top: 2rem;
    display: flex;
    justify-content: flex-end;

    button + button,
    button + div,
    div + button {
      margin-left: 1.2rem;
    }
  }
}
