@import "config";

:global {
    .highcharts-tooltip > span {
        background-color: var(--chart-tooltip-background-color);
        box-shadow: var(--zdepth-shadow-2);
        border: none;
        padding: 1.6rem;
    }
}

.tooltip-title {
    font-size: 1rem;
    line-height: 1.3rem;
    color: var(--chart-tooltip-title-font-color);
    font-weight: 700;
    letter-spacing: .05rem;
    text-transform: uppercase;
    display: block;
    margin-bottom: .8rem;
    font-family: var(--font-family-montserrat);
}

.tooltip-series {
    display: block;
}

.tooltip-series + .tooltip-series {
    margin-top: .6rem;
}

.tooltip-label, .tooltip-value {
    color: var(--chart-tooltip-series-label-font-color);
    font-size: 1.3rem;
    font-family: var(--font-family-nunito-sans);
}

.tooltip-value {
    font-weight: 700;
}

.chart {
    position: relative;

    .inner {
        width: 100%;
        height: 100%;
    }
}
