@import "config";

.filter {
  width: 100%;
  margin-top: 2.4rem;

  .label {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .08rem;
    color: var(--reporting-filter-label-font-color);
    line-height: 1.4rem;
    font-family: var(--font-family-montserrat);
    margin-bottom: 1.6rem;
  }

  .inputs {
    display: flex;
    align-items: center;

    .input {
      margin-right: 2rem;
    }

    .groups {
      min-width: 20.6rem;
    }

    .delete {
      cursor: pointer;
      display: flex;
      color: var(--reporting-filter-icon-color);

      > svg {
        fill: var(--reporting-filter-icon-color);
      }
    }
  }
}
