@mixin truncate ($max_width: 100%) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: $max_width;
}

@mixin user-select-none {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

@mixin circle ($size, $color) {
    width: $size;
    height: $size;
    background-color: $color;
    border-radius: 100%;
}