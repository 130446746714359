@import "config";

.navLinkGroup {
  position: relative;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid .3rem var(--nav-link-group-border-bottom-color);

  .navLinkGroupInside {
    color: var(--nav-link-group-text-color);
    padding: 2rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    display: flex;
    align-items: center;
  }

}

.navLinkGroup:hover {
  background-color: var(--nav-link-group-background-color);
  border-bottom: solid .3rem var(--nav-link-group-border-bottom-color-hover);
}
