.container {
  max-width: 60rem;
  min-height: 50rem;
  display: flex;
  flex-direction: column;
}
@mixin commonDiffTableStyles {
  width: 100%;
  flex: 1;
  flex-basis: 0;
  min-height: 30rem;
  border-top: solid 0.1rem #dbdbdb;

  [data-columns-wrapper='true'] {
    background-color: #fff;
  }

  [data-virtualized-header='true'] [data-row='true']:first-child {
    z-index: 3;
  }
}

.diffTable {
  @include commonDiffTableStyles;
  height: 100%;
}

.editDiffTable {
  @include commonDiffTableStyles;
  height: 98%;
}

.actions {
  display: flex;
  margin-bottom: 2rem;

  button + button {
    margin-left: auto;
  }
}
