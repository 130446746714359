.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  .actions {
    display: flex;

    .action {
      margin-left: .5rem;
      cursor: pointer;
    }
    .action > svg {
      fill: var(--icon-chart-header-color);
    }
  }
}
