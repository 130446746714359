.notificationItem {
    cursor: pointer;
    padding: 1.8rem 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 1.2rem;
    background-color: var(--user-notifications-background-color);
    margin: 1.5rem 3rem;
    transition: all 350ms ease, transform 350ms ease;
    position: relative;

    &.selected {
        background-color: var(--user-notifications-selected-background-color);
        box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
        z-index: 1;
    }

    .left {
        display: flex;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .text {
            overflow: hidden;
            position: relative;
            margin: 0 5px 0 5px;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: var(--label-color);
            flex-direction: column;
            display: flex;

            &.seen {
                color: #747474;
            }

            .subjectContainer {
                display: flex;
                flex-direction: row;

                .subject {
                    font-weight: 700;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .notificationType {
                font-size: 12px;
            }

            .date {
                margin-top: 5px;
                font-size: 12px;
                font-weight: 600;
                color: var(--label-color);

                &.seen {
                    color: #9e9b9b;
                    font-weight: normal;
                }
            }

            .label {
                white-space: nowrap;
            }
        }
    }

    .right {
        .date {
            font-size: 12px;
            font-weight: 600;
            color: var(--label-color);

            &.seen {
                color: #9e9b9b;
                font-weight: normal;
            }
        }
    }

    .dot {
        height: 10px;
        min-width: 10px;
        background-color: #f86521;
        border-radius: 50%;
        margin: 7px 2px;
        opacity: 1;

        &.seen {
            opacity: 0;
        }
    }

    .icon {
        margin-bottom: 0.5rem;
        flex-direction: column;
        margin-right: 5px;

        .img {
            width: 5rem;
            height: 5rem;
            display: block;
            border-radius: .2rem;
            //@include circle(4rem);

            &.seen {
                filter: grayscale(100%);
            }
        }
    }

    .label {
        //@include truncate(100%);
        overflow: hidden;
    }
}.notification {
     cursor: pointer;
     padding: 1.8rem 1.5rem;
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: space-between;
     border-radius: 1.2rem;
     background-color: var(--user-notifications-background-color);
     margin: 1.5rem 3rem;
     transition: all 350ms ease, transform 350ms ease;
     position: relative;

     &.selected {
         background-color: var(--user-notifications-selected-background-color);
         box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
         z-index: 1;
     }

     .left {
         display: flex;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;

         .text {
             overflow: hidden;
             position: relative;
             margin: 0 5px 0 5px;
             text-decoration: none;
             text-overflow: ellipsis;
             white-space: nowrap;
             color: var(--label-color);
             flex-direction: column;
             display: flex;

             &.seen {
                 color: #747474;
             }

             .subjectContainer {
                 display: flex;
                 flex-direction: row;

                 .subject {
                     font-weight: 700;
                     white-space: nowrap;
                     overflow: hidden;
                     text-overflow: ellipsis;
                 }
             }

             .notificationType {
                 font-size: 12px;
             }

             .date {
                 margin-top: 5px;
                 font-size: 12px;
                 font-weight: 600;
                 color: var(--label-color);

                 &.seen {
                     color: #9e9b9b;
                     font-weight: normal;
                 }
             }

             .label {
                 white-space: nowrap;
             }
         }
     }

     .right {
         .date {
             font-size: 12px;
             font-weight: 600;
             color: var(--label-color);

             &.seen {
                 color: #9e9b9b;
                 font-weight: normal;
             }
         }
     }

     .dot {
         height: 10px;
         min-width: 10px;
         background-color: #f86521;
         border-radius: 50%;
         margin: 7px 2px;
         opacity: 1;

         &.seen {
             opacity: 0;
         }
     }

     .icon {
         margin-bottom: 0.5rem;
         flex-direction: column;
         margin-right: 5px;

         .img {
             width: 5rem;
             height: 5rem;
             display: block;
             border-radius: .2rem;
             //@include circle(4rem);

             &.seen {
                 filter: grayscale(100%);
             }
         }
     }

     .label {
         //@include truncate(100%);
         overflow: hidden;
     }
 }
