@import "config";

.summary {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .item {
        transition: background-color 200ms;
        color: var(--dashboard-summary-item-color);
        text-align: center;
        position: relative;
        flex: 1;
        padding: 1.6rem 0;

        &:before {
            left: 0;
        }

        &:after {
            right: -1px;
        }

        &:before, &:after {
            transition: transform 200ms;
            position: absolute;
            top: 0;
            content: "";
            width: 1px;
            height: 100%;
            background-color: var(--dashboard-summary-item-line-color);
            transform: scaleY(0.67);
        }

        &:first-child:before, &:last-child:after {
            display: none;
        }

        &:hover {
            background-color: var(--dashboard-summary-item-hover-background-color);

            &:before, &:after {
                transform: scaleY(1);
            }
        }

        .value {
            font-size: 3rem;
            margin-bottom: 1.2rem;
            font-weight: 700;
            font-family: var(--font-family-montserrat);
        }

        .label {
            color: var(--dashboard-summary-label-font-color);
        }
    }

    .loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--dashboard-summary-loader-background-color);
        z-index: 1;
    }
}
