.filter {
  border-radius: var(--button-border-radius);
  border: solid 0.1rem var(--button-flat-hover-border-color);
  padding: 1rem;
  margin-top: 3rem;

  .label {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .08rem;
    color: var(--reporting-filter-label-font-color);
    line-height: 1.4rem;
    font-family: var(--font-family-montserrat);
    margin-bottom: 1.6rem;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    >* {
      margin-right: 1rem;
    }

    .delete {
      padding: 1rem 0 1rem 1rem;
    }
  }

}
