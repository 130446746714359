@import "config";

.themeSwitch {
  padding: 1.5rem;
  position: relative;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid .3rem var(--nav-link-group-border-bottom-color);
}

.themeSwitch:hover {
  background-color: var(--nav-link-group-background-color);
  border-bottom: solid .3rem var(--nav-link-group-border-bottom-color-hover);
}

.drawer {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 104%;
  right: 0;
  background-color: var(--theme-switch-group-background-color);
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);

  .drawerRow {
    color: var(--nav-group-text-color);
    border-bottom-width: .1rem;
    border-bottom-color: var(--nav-group-border-bottom-color);
  }

  .drawerRow:hover {
    background-color: var(--nav-group-background-color-hover);
  }

  .icon {
    padding-right: 1rem;
    color: var(--nav-group-text-color);
  }
}
