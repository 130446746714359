.quickFilter {
  &:hover {
    .icon {
      opacity: 1;
    }
  }

  .icon {
    opacity: 0;
    cursor: pointer;

    [class^='icon-'] {
      color: var(--quick-filter-icon-color);
    }
  }
}
