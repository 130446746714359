.editHistoryModal {
  padding: 10rem;

  .actions {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }
}

.list {
  height: 40vh;
}

.row {
  height: 4rem;
  font-size: 1.3rem;
}

.action {
  margin-left: 1rem;
  cursor: pointer;
}

.links {
  display: flex;

  button + button {
    margin-left: 1rem;
  }
}

.historyReportsLabel {
  margin: 1rem;
}
