.table {
  height: 90vh;
  flex: 1;
  flex-basis: 0;
}

.row {
  height: 4rem;
  font-size: 1.3rem;
}

.column {
  padding: 1rem;
}

.message {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 100px;

  .loader {
    margin-bottom: 3rem;
  }
}
