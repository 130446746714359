@import "config";

.navGroup {
  color: var(--nav-group-text-color);
  background-color: var(--nav-group-background-color);
  border-bottom-width: .1rem;
  border-bottom-color: var(--nav-group-border-bottom-color);
  white-space: nowrap;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 500;
}

.navGroup:hover {
  background-color: var(--nav-group-background-color-hover);
}
