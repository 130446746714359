html.dark {
  --main-menu-background-color: var(--color-dark);

  --nav-link-group-border-bottom-color: var(--color-dark);
  --nav-link-group-border-bottom-color-hover: var(--color-blue-1);
  --nav-link-group-background-color: var(--color-dark-light);
  --nav-link-group-text-color: var(--color-white);

  --app-bar-search-background-color: var(--dark-color);

  --accounts-list-border-bottom-color: var(--color-dark);
  --accounts-list-border-bottom-color-hover: var(--color-blue-1);
  --accounts-list-background-color: var(--color-dark-light);

  --nav-group-background-color: var(--color-dark-2);
  --nav-group-background-color-hover: var(--color-dark-light);
  --nav-group-border-bottom-color: rgba(255, 255, 255, 0.2);
  --nav-group-text-color: var(--color-white);

  --reporting-header-background-color: #434141;

  --theme-switch-group-background-color: var(--color-dark-2);

  --reporting-filter-icon-color: var(--color-white);

  --list-header-background-color: var(--color-dark-2);
  --table-border-top-color: #000000;

  --box-loader-background-color: #434141;
  --box-loader-linear-gradient: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, var(--card-loader-background-color, #383636) 50%, rgba(0, 0, 0, 0) 100%);

  --vidazoo-user-notifications-header-background-color: var(--color-shadow-2);
  --user-notifications-background-color: #353535;
  --user-notifications-items-conatiner-background-color: #212121;
  --user-notifications-items-disabled-arrow-fill-color: #676767;
  --user-notifications-selected-background-color: #242424;


  --border-chart-layout-color: var(--color-white);
  --charts-form-layout-color: #353535;
}
