.notificationMessage {
    padding: 0 20px;
    max-width: 65vw;

    tbody {
        tr {
            td {
                padding: 1rem 2rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &:nth-child(even) {
                //background-color: var(--color-gray-4);
            }
        }

        tr + tr {
            border-top: solid .1rem var(--color-gray-3);
        }
    }

    .top {
        display: flex;
        flex-direction: row;
        color: var(--label-color);
        font-size: 14px;

        .icon {
            margin-bottom: 0.5rem;
            flex-direction: column;
            margin-right: 5px;

            img {
                width: 10rem;
                height: 10rem;
                display: block;
                border-radius: .5rem;
                border: 1px solid #c5c5c5;
            }
        }

        .topText {
            margin: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .subject {
                font-weight: 700;
                font-size: 2rem;
                font-family: 'Montserrat', sans-serif;
            }
        }

        .date {
        }

        .author {
        }
    }

    .bottom {
        color: var(--label-color);
        min-height: 0;
        display: flex;
        flex-direction: column;
        overflow: auto;
        max-height: calc(100vh - 350px);
        padding: 1rem 2rem;

        .message {
            font-weight: 300;
        }
    }
}

.notificationEmpty {
    display: flex;
    align-self: center;
    margin: 0 auto;
}
