
.colorPicker {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  min-width: 15%;
  position: relative;
}

  .color {
    min-width: 5rem;
    height: 3rem;
    border-radius: 2px;
  }

  .swatch {
    padding: 5px;
    background: var(--chip-background-color);
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    display: inline-block;
    cursor: pointer;
  }

  .popover {
    position: absolute;
    top: 7rem;
    right: 0;
    z-index: 2;
  }

  .cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .label {
    font-size: var(--field-label-font-size);
    color: var(--field-label-color);
    letter-spacing: var(--field-label-letter-spacing);
    font-weight: var(--field-label-font-weight);
    text-transform: uppercase;
    transition: color 200ms;
  }

