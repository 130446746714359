
.actions {
  display: flex;
  justify-content: space-between;

  button + button {
    margin-left: 2rem !important;
  }

  .buttons {
    display: flex;

  }
}
