@import "config";

.surface {
  padding: 1.4rem 2.4rem;
  position: relative;
  transition: max-height 200ms;
  border-bottom: solid 0.1rem #dbdbdb;

  .head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.7rem;
    align-items: center;
    padding: 0 2rem;

    .switch {
      margin: 0 2rem;
    }
  }

  .top {
    min-width: 0;
    padding: 0 1rem;

    .presets {
      padding: 0 1rem;
    }

    .date {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .timezone {
      border: var(--picker-border);
      padding: 0.5rem;
      border-radius: 4px;
      box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
      background-color: var(--day-and-hour-select-selected-date-backgroud);
      color: var(--day-and-hour-select-selected-date-color);
      font-weight: 600;
    }

    .compare {
      padding-left: 1.2rem;
    }

    .title {
      margin-right: 3.6rem;
    }

    @media screen and (max-width: 900px) {
      .preset {
        display: none;
      }
    }
  }

  .timeSelectors {
    display: flex;
    align-items: flex-start;
  }

  .compareSwitch {
    padding: 1rem 0 0 1.2rem;
  }

  .compareTitle {
    margin-bottom: 1rem;
  }

  .timeframe {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 2.8rem;

    .date {
      width: 13.0rem !important;
      min-width: auto;
      margin-right: 1.2rem;
    }

    .hour {
      width: 10.0rem !important;
    }

    .timezone, .period {
      margin-left: 3.2rem;
      min-width: 16rem;
    }
  }

  .timeframe + .timeframe {
    margin-top: 1.2rem;
  }

  .bottom {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.8rem;
    padding-top: 1.8rem;

    .left, .right {
      flex: 1;
    }

    .left {
      margin-right: 8rem;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;

    button + button {
      margin-left: 1.2rem;
    }
  }

  .chipsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: .8rem;

    .description {
      display: flex;
      align-items: center;

      .label {
        line-height: 1.6rem;
        font-size: 1.3rem;
        letter-spacing: .125rem;
        font-weight: 600;
        text-transform: uppercase;
        font-family: var(--font-family-montserrat);
        color: var(--reporting-header-chips-header-label-font-color);
      }

      .infocon {
        margin-left: .8rem;
      }
    }
  }

  .link {
    color: var(--reporting-header-chips-header-link-color);
  }
}

.linkedTip {
  display: flex;
  flex-direction: column;
  align-items: center;

  .link {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 1920px) {

}

@media only screen and (max-width: 960px) {
  .surface .bottom {
    display: block;

    .left {
      margin-right: 0;
    }
  }
}

.metricsAndCapsules {
  box-sizing: border-box;
  padding: var(--item-padding);
  list-style-type: none;
  margin: 0;
  display: flex;
  align-items: center;
  border-bottom: solid 1px var(--item-border-color);
  width: 100%;
  position: relative;
  overflow: hidden;
  cursor: default;
  background-color: var(--item-selected-background-color);


  &:hover:not(.disabled), &.active {
    background-color: var(--item-selected-background-color);
  }

  .label {
    font-size: var(--item-label-font-size);
    color: var(--primary-color) !important;
    font-weight: bold;
    font-family: var(--item-label-font-family);
    white-space: nowrap;
  }

}
