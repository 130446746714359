.table {
  width: 100%;
  height: 97%;
}

.summary {
  padding: 1rem;
  width: 100%;
  height: 97%;
  overflow: hidden;
}

.performance {
  padding: 1rem;
  width: 100%;
  height: 97%;
  overflow: hidden;
}

.list {
  height: 60rem;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
