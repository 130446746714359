.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 2rem;
    z-index: 2147483647;

    &.left {
        align-items: flex-start;
    }

    &.right {
        align-items: flex-end;
    }
}

.alert+.alert {
    margin-top: 1.8rem;
}

.alert {
    .inner {
        position: relative;
        pointer-events: all;
        border-radius: var(--border-radius);
        background-color: var(--color-white);
        border-bottom: solid .1rem var(--color-app-blue-darker);
        box-shadow: 0 .3rem .6rem rgba(0, 0, 0, .16);
        min-width: 30rem;
    }

    .button {
        border-radius: var(--border-radius);
        padding: .7rem;
        margin: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
        color: var(--color-blue-white);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 100ms;
        font-size: 1.6rem;

        &[data-primary] {
            background-color: rgba(255, 255, 255, .1);
        }

        &:hover {
            background-color: rgba(255, 255, 255, .1);
        }

        &:active {
            background-color: rgba(255, 255, 255, .2);
        }
    }

    .header {
        padding: .5rem .5rem 0 .5rem;
        display: flex;
        justify-content: flex-end;

        .button {
            border-radius: 100%;
            width: 3.2rem;
            height: 3.2rem;
            padding: 0;
            font-size: 2rem;
        }
    }

    .view {
        padding: .5rem 1.1rem .8rem 1.1rem;
        color: var(--color-blue-white);
        max-width: 30rem;
        line-height: 1.4;
    }

    .footer {
        margin-top: .7rem;
        display: flex;
        justify-content: flex-end;

        .button+.button {
            margin-left: .5rem;
        }
    }

    .label {
        margin-bottom: .5rem;
    }

    .accent {
        --accent-color: transparent;
        border-radius: var(--border-radius);
        position: absolute;
        top: 1rem;
        left: 1rem;
        width: 1rem;
        height: 1rem;
        pointer-events: none;

        &.success {
            --accent-color: var(--color-green);
            background-color: var(--color-green);
        }

        &.error {
            --accent-color: var(--color-red);
            background-color: var(--color-red);
        }

        &.warning {
            --accent-color: var(--color-yellow);
            background-color: var(--color-yellow);
        }

        &.info {
            --accent-color: var(--color-blue);
            background-color: var(--color-blue);
        }

        &.natural {
            background-color: transparent;
        }

        .loader {
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-.4rem, -.4rem);

            svg circle {
                stroke: var(--accent-color);
            }
        }
    }

}
