@import 'config';

@keyframes slide {
    0% {
        right: 200%;
    }

    100% {
        right: -200%;
    }
}

.loader {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 1.8rem;
    border-radius: 1.8rem;
    background-color: var(--box-loader-background-color);

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 250%;
        background: var(--box-loader-linear-gradient);
        animation: slide 2s ease infinite;
        animation-delay: var(--animationDelay);
        pointer-events: none;
        z-index: 4;
        top: 0;
        transform: translateY(-25%) rotate(-15deg);
    }
}
