:root {
    --chart-x-axis-label-color: var(--base-color);
    --chart-y-axis-label-color: var(--color-gray-opacity-40);
    --chart-y-axis-grid-line-color: var(--color-gray-opacity-10);

    --chart-tooltip-line-color: var(--color-gray-opacity-10);
    --chart-tooltip-background-color: var(--color-white);
    --chart-tooltip-title-font-color: var(--base-color);
    --chart-tooltip-series-label-font-color: var(--base-color);

    --chart-line-color-1: var(--color-purple);
    --chart-line-color-1-gradient-top: rgba(149, 37, 170, 0.1);
    --chart-line-color-1-gradient-bottom: rgba(149, 37, 170, 0.0);

    --chart-line-color-2: var(--color-blue);
    --chart-line-color-2-gradient-top: rgba(9, 133, 249, 0.1);
    --chart-line-color-2-gradient-bottom: rgba(9, 133, 249, 0.0);

    --chart-marker-color: var(--color-white);

    --chart-x-axis-line-color: var(--color-gray-opacity-40);
}
