@import "../../../../../../../node_modules/@vidazoo/ui-kit/dist/styles/utils";

.container {
    cursor: default;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-height: 325px;
    width: 380px;

    .inner {
        width: 100%;
        min-height: 0;
        display: flex;
        flex-direction: column;
        overflow: auto;
        scrollbar-color: var(--color-black) transparent;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: .5rem;
            height: .5rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--color-black);
            border-radius: .5rem;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
    }

    .notifications {
        width: 100%;

        .notification {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            border-radius: var(--global-border-radius);
            width: 95%;
            margin: 0.75rem;
            padding: 0.75rem;

            &:hover {
                background-color: var(--user-notifications-items-conatiner-background-color);
            }

            .text {
                overflow: hidden;
                position: relative;
                margin: 0 5px 0 5px;
                text-decoration: none;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: var(--table-data-column-color);

                &.seen {
                    color: #bebdbd;
                }

                .subjectContainer {
                    display: flex;
                    flex-direction: row;

                    .subject {
                        font-weight: 700;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .message {
                    font-size: 12px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    letter-spacing: 0.5px;
                }

                .label {
                    font-size: 12px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .dot {
                height: 10px;
                min-width: 10px;
                background-color: #f86521;
                border-radius: 50%;
                margin: 7px 2px;
                opacity: 1;

                &.seen {
                    opacity: 0;
                }
            }

            .icon {
                margin-bottom: 0.5rem;
                flex-direction: column;
                margin-right: 5px;

                .img {
                    width: 5rem;
                    height: 5rem;
                    display: block;
                    border-radius: .2rem;
                    @include circle(4rem);

                    &.seen {
                        filter: grayscale(100%);
                    }
                }
            }

            .label {
                @include truncate(100%);
                overflow: hidden;
            }
        }
    }
}
