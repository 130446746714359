@mixin circle($size) {
    width: $size;
    height: $size;
    border-radius: 50%;
}

@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.drawer {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 104%;
    right: 0;
    padding: 1.2rem;
    background-color: var(--user-notifications-background-color);
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}

.icon {
    display: flex;
    position: relative;

    [class^='icon-'] {
        color: var(--main-menu-icon-color);
    }

    .badge {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        transform: translate(-50%, -50%);
    }

    .badgeBottom {
        position: absolute;
        z-index: 10000;
        top: 40px;
        right: -10px;
        padding: 5px;
        white-space: nowrap;
        border-radius: unset;
        border-top: unset;
        min-width: 55px;
        transition: opacity .2s;
        opacity: 0;
    }

    .badgeBottomSign {
        width: 20px;
        height: 20px;
        background-color: var(--badge-blue-color);
        position: absolute;
        z-index: 1;
        top: 32px;
        left: 5px;
        transform: rotate(45deg);
        transition: opacity .1s;
        opacity: 0;
    }

    .showBadgeLabelBottom {
        opacity: 1;
    }
}

.notificationPanel {
  padding: 1.5rem;
  position: relative;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid .3rem var(--nav-link-group-border-bottom-color);
}

.notificationPanel:hover {
  background-color: var(--nav-link-group-background-color);
  border-bottom: solid .3rem var(--nav-link-group-border-bottom-color-hover);
}
