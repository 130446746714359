@import "../../../../../../common/styles/mixins";
@import "config";

.userMenu {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.8rem 1.6rem;
  cursor: pointer;

  height: 100%;
  justify-content: center;
  border-bottom: solid .3rem var(--nav-link-group-border-bottom-color);

  .picture {
    border-radius: 100%;
    overflow: hidden;
    border: solid .2rem var(--color-purple-2);
    padding: .2rem;

    img {
      border-radius: 100%;
      display: block;
      width: 4.8rem;
      height: 4.8rem;
      background-color: var(--color-purple-2);
    }
  }

  .info {
    margin-left: 1.4rem;
    min-width: 0;
    padding-right: 2.4rem;
    @include user-select-none;

    .name {
      color: var(--color-purple-2);
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 1;
      margin-bottom: .2rem;
    }

    .email {
      color: var(--color-gray-1);
      font-size: 1.4rem;
      font-weight: 300;
      line-height: 1;
      @include truncate;
    }
  }

  .icon {
    @include user-select-none;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.6rem;

    &.expand {
      i {
        transform: rotate(180deg);
      }
    }

    i {
      transition: transform 150ms;
      transform-origin: center;
      color: var(--color-gray-1);
    }
  }

}

.userMenu:hover {
  background-color: var(--nav-link-group-background-color);
  border-bottom: solid .3rem var(--nav-link-group-border-bottom-color-hover);
}

.drawer {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 104%;
  right: 0;
  background-color: var(--nav-group-background-color);
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}
