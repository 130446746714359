@import "config";

.drawer {
  width: 50rem;
  background-color: transparent !important;
}

.link {
  background-color: var(--history-log-list-background-color);
  padding: 0 0 2rem 3rem;
}

.item {

  padding: 2.8rem 0;

  & + .item {
    border-top: solid .1rem var(--history-log-item-border-color);
  }

  .header {

    margin-bottom: 2.5rem;
    display: flex;
    align-items: flex-start;

    .avatar {
      width: 4rem;
      height: 4rem;
      margin-right: 1.2rem;

      > svg {
        width: 4rem !important;
        height: 4rem !important;
      }

      > img {
        width: 3.4rem;
        height: 3.4rem;
      }
    }

    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-self: stretch;
      justify-content: space-between;

      .title {

        display: flex;
        font-family: var(--font-family-montserrat);
        color: var(--history-log-item-title-font-color);
        font-weight: 600;
        font-size: 1.4rem;

        .author {
          margin-right: .5rem;

          &.create {
            color: var(--primary-success-color);
          }

          &.update {
            color: var(--primary-color);
          }

          &.delete {
            color: var(--primary-error-color);
          }
        }
      }

      .date {
        font-size: 1.2rem;
        font-weight: 500;
        font-style: italic;
        color: var(--history-log-item-date-font-color);
        font-family: var(--font-family-montserrat);
      }
    }
  }

  .levelWrapper {
    border-left: solid .2rem var(--history-log-item-level-border-color);
    margin-bottom: 1.2rem;
    padding-left: 1.2rem;
    font-weight: 400;
    font-family: var(--font-family-nunito-sans);
    font-size: 1.4rem;
    color: var(--history-log-level-font-color);

    > .levelWrapper {
      margin-top: 1.2rem;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .prop {
      font-weight: bold;
    }

    .value {
      font-weight: bold;
      word-wrap: break-word;
    }

    .codeScrollArea {
      width: 100%;
      max-height: 15rem;
      margin-top: .5rem;
    }

    pre.code {
      margin: 0;
      padding: .7rem;
      background-color: var(--history-log-code-background-color);
      border-radius: var(--global-border-radius);
    }
  }
}

.message {
  padding: 8rem 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  background-color: var(--history-log-message-background-color);

  .loader {
    margin-top: 4rem;
    height: 3rem;
    margin-bottom: 2.6rem;
  }
}

.list {
  background-color: var(--history-log-list-background-color);
  padding: 0 3.2rem;
}
