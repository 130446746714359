.container {
  max-width: 60rem;
  min-height: 50rem;
  display: flex;
  flex-direction: column;
}

.table {
  width: 100%;
  flex: 1;
  flex-basis: 0;
  min-height: 30rem;
  border-top: solid .2rem var(--table-border-top-color);

  [data-columns-wrapper='true'] {
    background-color: #fff;
  }

  [data-virtualized-header='true'] [data-row='true']:first-child {
    z-index: 3;
  }
}

.actions {
  display: flex;
  margin-bottom: 2rem;

  button + button {
    margin-left: auto;
  }
}

.header {
  background-color: var(--list-header-background-color);
}

.name {
  color: var(--primary-color);
  text-decoration: none;
    cursor: pointer;

  &:hover {
    text-decoration: underline;

  }
}


