@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../../../node_modules/@vidazoo/ui-kit/dist/styles/variables";
@import "../../../node_modules/@vidazoo/date-range/dist/styles/themes/dark";
@import "../../../node_modules/react-grid-layout/css/styles.css";
@import "../../../node_modules/react-resizable/css/styles.css";

@import "fonts";
@import "colors";
@import "variables";

@import "./themes/dark";

:global {
  @import "~react-grid-layout/css/styles";

  .material-icons {
    font-size: inherit;
    overflow: hidden;
  }
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;

  body {
    min-height: 100vh;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    background-color: var(--primary-body-color);
    color: var(--base-color);
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
  }

  #root {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  * {
    box-sizing: inherit;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }
}

#g-recaptcha {
  display: flex;
  justify-content: center;
}

input:focus-visible {
  outline-color: var(--primary-color);
}

.card {
  background: #fff;
  border: solid .1rem var(--color-gray-3);
  border-radius: var(--general-border-radius);
}

table {
  table-layout: fixed;

  thead {
    tr {
      border-bottom: solid .1rem var(--color-gray-3);

      th {
        font-weight: 500;
        padding: 1rem 2rem;
        text-align: left;
        color: var(--color-gray-1);
        text-transform: uppercase;
        font-size: 1.4rem;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 1rem 2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:nth-child(even) {
        background-color: var(--color-gray-4);
      }
    }

    tr + tr {
      border-top: solid .1rem var(--color-gray-3);
    }
  }
}
