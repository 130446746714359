
.chipsContainer {
  background-color: rgba(0, 5, 28, 0.8);
  backdrop-filter: saturate(180%) blur(10px);
  position: sticky;
  top: 68px;
  display: flex;
  min-height: 60px;

  .searchContainer {
    position: relative;

    .iconContainer {
      width: 45px;
      height: 40px;
      position: absolute;
      right: 12px;
      top: 5px;
      background-color: #83ff43;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      cursor: pointer;
    }

    .searchValue {
      border-radius: 16px;
      font-size: 14px;
      transition: all .3s;
      background-color: #83ff43;
      font-weight: bold;
      white-space: nowrap;
      margin: 5px 12px 0 20px;
      width: 45px;
      height: 40px;
      border: none;
      padding: 12px;
    }

    .searchValue:focus-visible {
      outline: none;
      width: 200px;
    }
  }

  .chipsOutside::-webkit-scrollbar {
    width: 0 !important;
  }

  .chipsOutside {
    overflow-x: scroll;
    padding-top: 5px;

    .chips {
      display: flex;
      white-space: nowrap;

      .chip {
        border-radius: 16px;
        background-color: #77B955;
        font-size: 14px;
        cursor: pointer;
        padding: 10px;
        transition: all .3s;
        font-weight: bold;
        white-space: nowrap;
        margin-left: 12px;

        a {
          text-decoration: none;
          color: black;
        }

      }

      .chipSelected {
        background-color: #83ff43;
      }

      .chip:hover {
        background-color: #487c2e;
      }
    }
  }
}
