.container {
  margin-bottom: 1rem;

  .type {
    position: relative;
  }

  .type:hover {
    box-shadow: 0 0 29px -6px rgba(0,0,0,0.44);
  }

  .selected {
    box-shadow: 0 0 29px -6px rgba(0,0,0,0.74);
  }

  .image {
    box-shadow: 0 0 29px -6px rgba(0,0,0,0.44);
    width: 130px;
    height: 75px;
    background-size: contain;
    background-position: center;
    position: absolute;
    transition: opacity .3s ease-in-out;
    border-radius: .3rem;
    bottom: 47px;
    left: 0;
    opacity: 0;
  }
  .imageHover {
    opacity: 1;
  }
}
