@import "../../../../../../common/styles/mixins";

.info {
    max-height: 345px;
    width: 380px;
    display: flex;
    align-items: center;

    .header {
        width: 95%;
        margin: 0.5rem;
        padding: 1rem;
        background-color: var(--vidazoo-user-notifications-header-background-color);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: var(--global-border-radius);

        .leftHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0.5rem;
            font-family: var(--label-monserrat-font-family);
            color: var(--table-data-column-color);

            .pendingNotifications {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                .counterLabel {
                    display: flex;
                    font-weight: 600;
                    font-size: 22px;
                    text-transform: uppercase;
                }

                .pendingLabel {
                    font-weight: 500;
                    font-size: 14px;
                    text-transform: lowercase;
                    margin: 0 10px;
                    transition: all 200ms;
                    letter-spacing: 0.1px;
                }
            }

            .settings {
                cursor: pointer;
                font-weight: 600;
                letter-spacing: 0;
                font-size: 12px;
                padding-left: 3px;
            }

            .viewAll {
                cursor: pointer;
                font-weight: 600;
                letter-spacing: 0;
                font-size: 14px;
            }
        }

        .rightHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: var(--label-monserrat-font-family);
            color: var(--label-color);

            .markAllAsRead {
                cursor: pointer;
                font-weight: 600;
                letter-spacing: 0;
                font-size: 12px;
                color: #f86521;
                transition: all .2s ease-in-out;

                &:hover {
                    color: #e05c1e;
                }
            }
        }

        svg + label {
            margin-left: .5rem;
        }
    }

}

.user {
    margin-left: 1rem;
}

.name,
.email {
    line-height: 1.1;
    color: #fff;
    @include truncate(12rem);
}

.email {
    font-size: 1.2rem;
    margin-top: .3rem;
    color: var(--color-gray);
}
