.modal {
  min-width: 47.6rem;

  .input {
    margin: 4rem 0;
  }

  .switch {
    margin-bottom: 4rem;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button + button {
      margin-left: 1.2rem;
    }
  }
}
