.wrapper {
    padding: 8.6rem 14.2rem 8.7rem 14.2rem;
    width: 100%;
    min-height: 100vh;
    flex: 1;
    background-color: var(--color-blue-1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-size: cover;
}

.tip {
    margin-bottom: 1.8rem;
    font-size: 1.2rem;
    font-weight: 300;
    color: #fff;
    text-align: center;

    span {
        font-weight: 400;
    }
}

.window {
    --transition-fn: cubic-bezier(.8, .5, .2, 1.4);
    --side-width: 45.41522491%;
    --br-size: 1.6rem;

    border-radius: var(--br-size);
    background-color: #fff;
    flex: 1;
    width: 100%;
    max-width: 115.6rem;
    max-height: 72.7rem;
    min-width: 106.4rem;
    min-height: 64rem;
    overflow: hidden;
    display: flex;
    position: relative;
    box-shadow: 2px 7px 7px rgba(0, 0, 0, 0.12);

    .logo {
        margin: 4rem 0 0 4.2rem;
        position: absolute;
        z-index: 20;

        h3 {
            font-size: 1.4rem;
            font-family: var(--font-secondary);
            color: var(--color-purple-2);
            margin: 0;
            padding: 0;
            line-height: 1;
            transform: translate(3.2rem, -.5rem);
            transition: color 200ms ease;
            transition-delay: 100ms;
        }
    }

    &.showForgotPassword {
        .logo {
            h3 {
                transition-delay: 350ms;
                color: #fff;
            }
        }

        .right {
            transform: translateX(100%);

            .clip {
                transition-delay: 0ms;
                clip-path: polygon(70% 0, 100% 0, 100% 100%, 15% 100%);
            }
        }

        .left {
            transform: translateX(0);

            .clip {
                transition-delay: 300ms;
                clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
            }
        }

        .middle {
            margin-right: 0;
            margin-left: var(--side-width);
        }
    }

    .left {
        transform: translateX(-100%);

        .clip {
            clip-path: polygon(0 0, 30% 0, 70% 100%, 0 100%);
        }
    }

    .right {
        transform: translateX(0);

        .clip {
            transition-delay: 300ms;
        }
    }
}

.left,
.right {
    width: var(--side-width);
    position: absolute;
    transition: transform 1s var(--transition-fn);
    min-height: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
}

.left {
    top: 0;
    left: 0;
    align-items: flex-start;

    .clip {
        clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
        top: 0;
        right: 0;
    }
}

.right {
    top: 0;
    right: 0;
    align-items: flex-end;

    .clip {
        top: 0;
        left: 0;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
    }
}

.middle {
    transition: margin 500ms var(--transition-fn);
    flex: 1;
    margin-right: var(--side-width);
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 15.1rem 0 15rem;

    footer {
        justify-self: flex-end;
        z-index: 1;
        margin-bottom: 1.5rem;

        ul {
            display: flex;

            li {
                a {
                    font-size: 1rem;
                    color: var(--color-gray-5);
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            li+li {
                margin-left: 2.5rem;
            }
        }
    }
}

.clip {
    position: absolute;
    background-color: var(--color-blue-1);
    width: 150%;
    height: 100%;
    transition: clip-path 500ms ease;
}

.forms {
    position: relative;
    width: 100%;
    flex: 1;
}
