@import "../../../../common/styles/mixins";

.table {
  width: 100%;
  flex: 1;
  flex-basis: 0;
  min-height: 30rem;
  border-top: solid .2rem var(--table-border-top-color);

  [data-columns-wrapper='true'] {
    background-color: #fff;
  }

  [data-virtualized-header='true'] [data-row='true']:first-child {
    z-index: 3;
  }
}

.column {
    padding: 1rem;
}

.header {
  background-color: var(--list-header-background-color);
}
