:root {
    --font-primary: "Wotfard", Sans-serif;
    --font-secondary: "Domaine", Sans-serif;
}

@font-face {
    font-family: 'Domaine';
    font-style: normal;
    font-weight: bold;
    font-display: auto;
    src: url('https://vidazoo-website-cdn.vidazoo.com/fonts/domaine-display-web-bold.eot');
    src: url('https://vidazoo-website-cdn.vidazoo.com/fonts/domaine-display-web-bold.eot?#iefix') format('embedded-opentype');
}

@font-face {
    font-family: 'Domaine';
    font-style: italic;
    font-weight: bold;
    font-display: auto;
    src: url('https://vidazoo-website-cdn.vidazoo.com/fonts/domaine-display-web-bold-italic.eot');
    src: url('https://vidazoo-website-cdn.vidazoo.com/fonts/domaine-display-web-bold-italic.eot?#iefix') format('embedded-opentype');
}

@font-face {
    font-family: 'Domaine';
    font-style: normal;
    font-weight: bold;
    font-display: auto;
    src: url('https://vidazoo-website-cdn.vidazoo.com/fonts/domaine-display-web-bold.woff2') format('woff2');
}

@font-face {
    font-family: 'Domaine';
    font-style: italic;
    font-weight: bold;
    font-display: auto;
    src: url('https://vidazoo-website-cdn.vidazoo.com/fonts/domaine-display-web-bold-italic.woff2') format('woff2');
}

@font-face {
    font-family: 'Wotfard';
    font-style: normal;
    font-weight: 100;
    font-display: auto;
    src: url("https://vidazoo-website-cdn.vidazoo.com/fonts/Wotfard-Thin.otf") format("opentype");
}

@font-face {
    font-family: 'Wotfard';
    font-style: italic;
    font-weight: 100;
    font-display: auto;
    src: url("https://vidazoo-website-cdn.vidazoo.com/fonts/Wotfard-ThinItalic.otf") format("opentype");
}

@font-face {
    font-family: 'Wotfard';
    font-style: normal;
    font-weight: 200;
    font-display: auto;
    src: url("https://vidazoo-website-cdn.vidazoo.com/fonts/Wotfard-ExtraLight.otf") format("opentype");
}

@font-face {
    font-family: 'Wotfard';
    font-style: italic;
    font-weight: 200;
    font-display: auto;
    src: url("https://vidazoo-website-cdn.vidazoo.com/fonts/Wotfard-ExtraLightItalic.otf") format("opentype");
}

@font-face {
    font-family: 'Wotfard';
    font-style: normal;
    font-weight: 300;
    font-display: auto;
    src: url("https://vidazoo-website-cdn.vidazoo.com/fonts/Wotfard-Light.otf") format("opentype");
}

@font-face {
    font-family: 'Wotfard';
    font-style: italic;
    font-weight: 300;
    font-display: auto;
    src: url("https://vidazoo-website-cdn.vidazoo.com/fonts/Wotfard-LightItalic.otf") format("opentype");
}

@font-face {
    font-family: 'Wotfard';
    font-style: normal;
    font-weight: normal;
    font-display: auto;
    src: url("https://vidazoo-website-cdn.vidazoo.com/fonts/Wotfard-Regular.otf") format("opentype");
}

@font-face {
    font-family: 'Wotfard';
    font-style: italic;
    font-weight: normal;
    font-display: auto;
    src: url("https://vidazoo-website-cdn.vidazoo.com/fonts/Wotfard-RegularItalic.otf") format("opentype");
}

@font-face {
    font-family: 'Wotfard';
    font-style: normal;
    font-weight: 500;
    font-display: auto;
    src: url("https://vidazoo-website-cdn.vidazoo.com/fonts/Wotfard-Medium.otf") format("opentype");
}

@font-face {
    font-family: 'Wotfard';
    font-style: italic;
    font-weight: 500;
    font-display: auto;
    src: url("https://vidazoo-website-cdn.vidazoo.com/fonts/Wotfard-MediumItalic.otf") format("opentype");
}

@font-face {
    font-family: 'Wotfard';
    font-style: normal;
    font-weight: 600;
    font-display: auto;
    src: url("https://vidazoo-website-cdn.vidazoo.com/fonts/Wotfard-SemiBold.otf") format("opentype");
}

@font-face {
    font-family: 'Wotfard';
    font-style: italic;
    font-weight: 600;
    font-display: auto;
    src: url("https://vidazoo-website-cdn.vidazoo.com/fonts/Wotfard-SemiBoldItalic.otf") format("opentype");
}

@font-face {
    font-family: 'Wotfard';
    font-style: normal;
    font-weight: 700;
    font-display: auto;
    src: url("https://vidazoo-website-cdn.vidazoo.com/fonts/Wotfard-Bold.otf") format("opentype");
}

@font-face {
    font-family: 'Wotfard';
    font-style: italic;
    font-weight: 700;
    font-display: auto;
    src: url("https://vidazoo-website-cdn.vidazoo.com/fonts/Wotfard-BoldItalic.otf") format("opentype");
}