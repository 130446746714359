:root {
    --color-reporting-sum-bar: #F2F2F3;
}
:global html.dark {
    --color-reporting-sum-bar: #282828;
}

.trend {
    opacity: .8;

    &.up {
        color: var(--color-green);
    }

    &.down {
        color: var(--color-red);
    }
}

.popover {
    text-align: left;

    .grid {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-column-gap: 2rem;
        grid-row-gap: 1rem;

        .item {
            align-items: center;

            .title {
                color: var(--color-gray-opacity-30);
                white-space: nowrap;
            }

            .value {
                font-size: 1.2rem;
            }
        }
    }
}

.list {
    height: 90vh;
}

.fullScreen {
    height: 84vh;
}

.row {
    height: 4rem;
}

.cell {
    width: 100%;
    height: 100%;
}

.freezeCell {
    @extend .cell;
    background-color: var(--color-reporting-sum-bar);
}

.header {
    background-color: var(--table-column-header-background-color);
    border-bottom: solid 0.1rem var(--table-column-header-bottom-border-color);
}

.sumBar {
    background-color: var(--color-reporting-sum-bar);
    border-bottom: 0.1rem solid var(--table-column-summary-header-bottom-border-color);
    font-family: var(--table-column-summary-header-font-family);
    color: var(--table-column-summary-header-font-color);
    border-right: 0.1rem solid var(--table-column-summary-header-side-border-color);
    line-height: 1.9rem;
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: 800;
    text-align: left;
    transition: background-color .2s;
    white-space: nowrap;
}

.sumBarBottom {
    @extend .sumBar;
    border-bottom: 0;
    border-top: 0.1rem solid var(--table-column-summary-header-bottom-border-color);
}

.tableSettings {
    background-color: rgba(0, 5, 28, 0.5);
    backdrop-filter: saturate(180%) blur(10px);
    color: white;
    padding: 1rem;
}

.link {
    color: #00b368;
}