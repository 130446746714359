.table {
  height: 68vh;
  flex: 1;
  flex-basis: 0;
}

.list {
  padding-top: 2rem;
  flex: 1;
  height: 200px;
}

.message {
  padding: 8rem 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .loader {
    margin-top: 4rem;
    height: 3rem;
    margin-bottom: 2.6rem;
  }
}
