.breadcrumbs {
  margin-bottom: 2.4rem !important;
}

.message {
  padding: 8rem 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .loader {
    margin-top: 4rem;
    height: 3rem;
    margin-bottom: 2.6rem;
  }
}

.sticky {
  position: sticky;
  top: 68px;
  z-index: 11;
}

.sliderPanel {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 1rem;

  button+button,
  button+div,
  div+button {
    margin-left: 1.2rem;
  }
}

.form {
  .surface {
    padding: 3.2rem 3.2rem 4.2rem;
    margin-top: 2.4rem;
  }

  .midContent {
    margin-bottom: 3rem;
  }

  .upperActions {
    margin-top: 3rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .actions {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    button + button, button + div, div + button {
      margin-left: 1.2rem;
    }
  }

  .warning {
    color: var(--color-orange);
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    align-items: center;

    span {
      margin-left: 0.5rem;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.8rem;

    button + button {
      margin-left: 1.2rem;
    }
  }

  .hidden {
    opacity: 0;
    border: none;
    padding: 0;
    margin: 0;
    height: 0px;
    display: block;
  }

  .strength {
    margin-top: 3.4rem;
  }

  div.row:last-of-type {
    margin-bottom: 0;
  }

  .hlineright {
    border-right-style: dashed;
    height: 10rem;
    margin: 0 2rem;
    border-right-width: .1rem;
    border-right-color: var(--hline-color);
  }

  .row {
    margin-bottom: 2rem;
    display: flex;
    align-items: flex-start;

    &.spaced {
      margin-bottom: 2.8rem;
    }

    .input {
      flex: 1;

      &.shrink {
        flex: inherit;
      }
    }

    .input + .input {
      margin-left: 2rem;
    }

    .radio + .radio {
      margin-left: 2.8rem;
    }

    .toggle + .toggle {
      margin-left: 2.8rem;
    }

    .check {
      margin-top: 1rem;
      margin-left: 2rem;
      align-self: center;
    }

    .disabled {
      color: #4e4e4e;
      font-size: 100px;
    }
  }

  .headerPadding {
    margin-bottom: 2.8rem;
    padding: 2rem;

    .buttons {
      display: flex;
      flex-direction: row-reverse;
    }

    button {
      margin: 1.2rem 0 1.2rem 1.2rem;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    margin-left: 12rem;

    div {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:first-child {
      margin-left: 0;
    }
  }

  .settings {
    margin-bottom: 2rem;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .buttons {
    display: flex;
    flex-direction: row-reverse;
    padding: 1rem 0 0 0;

    button {
      margin-left: 1.2rem;
    }
  }
}

.title {
  margin-bottom: 2.8rem;
}

.subtitle {
  margin-bottom: 2rem;
}

.button {
  position: relative;
}

//.grid {
//  display: flex;
//  flex-wrap: wrap;
//
//  &.gridOf4 {
//
//    .gridItem {
//      max-width: 25%;
//      flex: 1 1 100%;
//
//      &:nth-child(1n + 5) {
//        margin-top: 2rem;
//      }
//    }
//  }
//}

.footerListButton {
  border-top: solid .1rem var(--color-gray-opacity-40);
  padding: 1.6rem 3.2rem;
  display: flex;
  justify-content: center;
}

.labelHeaders {
  margin-right: 3.4rem;

  .label {
    color: var(--color-gray-opacity-40);
    margin-bottom: 2.2rem;
  }
}

.labelData {
  margin-bottom: 2.2rem;
}

.videoSurface {
  background-color: var(--color-gray-opacity-5);
}

.uploadFile {
  display: flex;
  align-items: center;

  .label {
    margin-left: 1rem;
  }
}

.actions {
  display: flex;

  button + button, button + div, div + button {
    margin-left: 1.2rem;
  }
}
